@import "_alert";

.button {    
    color : black;
    padding : 8.4px 14px;
    background-color: rgba(88, 64, 255, 0.082) !important;
    color : rgb(88, 64, 255);
    box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
    -webkit-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
    -moz-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
    border : 1px solid rgba(69, 50, 189, 0.082)
}

.table-cash-payment {
    tbody tr td {
        font-size: 13px;
        padding : 2px 5px;
    }
    tbody tr.bold td {
        font-weight: bold;
        border-top: 1px solid #cecece;
    }
    tbody tr.last-child td {
        padding-bottom: 20px;
    }
    margin-top: 20px;
}