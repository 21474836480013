
.button-group .button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.button-group .button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.button-group .button {
    padding: 0.25rem 1.25rem;
}

.button-group .btn-create {
    padding: 0.70rem 1.25rem;
}

.button {
    padding: 0.50rem 1.25rem;
}

.btn-icon {
    padding: 0.15rem 0.45rem !important;
    font-size: 12px;
}

.pagination div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.pagination div{
    border-right: none ;
}

.pagination div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 1px solid rgb(161 161 170) !important;
}

.pagination .active {
    background-color: rgb(96 165 250);
    color : #fff;
}