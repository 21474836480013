.videoInsert {
    position: absolute; 
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    z-index: -100;
    background-size: cover;
    overflow: hidden;
}

.detail-invoice {
    font-size: 13px;
    margin-top: 40px;
}

.detail-invoice .invoice-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

.detail-invoice .grid {
    margin-bottom: 2px;
}

.detail-invoice .total-invoice {
    font-size: 15px;
}

.detail-invoice .detail-product {
    font-style: italic;
}

.label-development {
    background-color: #f8d7da;
    width: 25px;
    height: 200px;
    position: absolute;
    right: 20px;
    top: -50px;
    transform: rotate(-45deg);
    transform-origin: right, top;    
    writing-mode:vertical-rl;
    padding-top: 40px;    
    padding-left: 20px;
    color : #721c24;
    font-size: 13px;
}

body {
    overflow-x: hidden;
}