.wavies > use{
    animation: waveAround 12s linear infinite;
    &:nth-child(1) { animation-delay:-2s; }
    &:nth-child(2) { animation-delay:-2s; animation-duration:5s }
    &:nth-child(3) { animation-delay:-4s; animation-duration:3s }
}

@keyframes waveAround{
    0% { transform: translate(-90px , 0%) }
    100% { transform: translate(85px , 0%) }
}

body {
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}